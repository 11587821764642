import saveAs from "file-saver";
import moment from "../config/moment";
import * as Icons from "@phosphor-icons/react";

export function compareObjects(object1: any, object2: any) {
  if (!object1 && !object2) {
    return true;
  }
  if (object1 && object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

export function zeroPad(num: number, places: number) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export function returnCurrency(currency: any) {
  switch (currency) {
    case "EUR": {
      return "€";
    }
    case "USD": {
      return "$";
    }
    default:
      break;
  }
}

export function getInitials(name: string) {
  try {
    let initials = "";

    if (!name) {
      return initials;
    }

    let names = name.trim().split(" ");

    if (names.length > 1) {
      initials = names[0][0] + names[names.length - 1][0];
    } else {
      initials = names[0][0];
    }

    return initials.toUpperCase();
  } catch {
    return "";
  }
}

export function getFirstLastName(name: string) {
  try {
    const names = name.trim().split(" ");
    if (names.length > 1) {
      return (
        names[0].slice(0, 1).toUpperCase() +
        names[0].slice(1).toLowerCase() +
        " " +
        names[names.length - 1].slice(0, 1).toUpperCase() +
        names[names.length - 1].slice(1).toLowerCase()
      );
    } else {
      return names[0];
    }
  } catch {
    return name;
  }
}

export function GetCustomDate(date1: any, date2: any) {
  const format = "YYYY-MM-DD";
  if (moment(date1, format).year() != moment(date2, format).year()) {
    return (
      moment(date1, format).format("LL") +
      " a " +
      moment(date2, format).format("LL")
    );
  } else if (
    moment(date1, format).month() != moment(date2, format).month() &&
    moment(date1, format).year() == moment(date2, format).year()
  ) {
    return (
      moment(date1, format).format("L") +
      " a " +
      moment(date2, format).format("LL")
    );
  } else {
    if (moment(date1, format).date() != moment(date2, format).date()) {
      return (
        moment(date1, format).date() +
        " a " +
        moment(date2, format).format("LL")
      );
    } else {
      return moment(date2, format).format("LL");
    }
  }
}

export function getYears(startYear: number) {
  const years = moment().diff(moment(`${startYear}-01-01`), "years");

  let options = [];
  for (let i = 0; i <= years; i++) {
    options.push({
      label: startYear + i,
      value: startYear + i,
    });
  }
  return options;
}

export function getHourFromMinutes(minutes: number) {
  const hours: number = Math.floor(minutes / 60);
  const minutesLeft: number = minutes % 60;

  const result: string = `${hours.toString().padStart(2, "0")}h${minutesLeft
    .toString()
    .padStart(2, "0")}`;

  return result;
}

export function obscureEmail(email: string) {
  const [name, domain] = email.split("@");
  return `${name[0]}${name[1]}${new Array(name.length).join("*")}@${domain}`;
}

export function obscurePhone(phone: string) {
  let toReturn = phone.toString().split("");
  for (let i = 2; i < toReturn.length - 2; i++) {
    toReturn[i] = "*";
  }
  return toReturn.toString().replaceAll(",", "");
}

export function CostumizeHour(value: any) {
  let values = value.split(":");
  values[0] = values[0].replace(/^0+/, "");
  let final = values[0] + "h" + (values[1] == "00" ? "" : values[1]);
  return final;
}

export function diffTime(horaInicio: string, horaFim: string): string {
  if (!horaInicio || !horaFim) {
    return "00:00";
  }
  const [inicioHoras, inicioMinutos] = horaInicio.split(":").map(Number);
  const [fimHoras, fimMinutos] = horaFim.split(":").map(Number);

  const minutosInicio = inicioHoras * 60 + inicioMinutos;
  const minutosFim = fimHoras * 60 + fimMinutos;

  let diffMinutos = minutosFim - minutosInicio;

  if (diffMinutos < 0) {
    diffMinutos += 24 * 60;
  }

  const diffHoras = Math.floor(diffMinutos / 60);
  const minutosRestantes = diffMinutos % 60;

  const horasFormatadas = diffHoras.toString().padStart(2, "0");
  const minutosFormatados = minutosRestantes.toString().padStart(2, "0");

  return `${horasFormatadas}:${minutosFormatados}`;
}

export function CompareNumbers(value1: number, value2: number, operator: any) {
  switch (operator) {
    case ">": {
      return +value1 > +value2;
    }
    case ">=": {
      return +value1 >= +value2;
    }
    case "<": {
      return +value1 < +value2;
    }
    case "<=": {
      return +value1 <= +value2;
    }
    case "=": {
      return +value1 == +value2;
    }
  }
}

export function getComparatorName(comparator: any, language?: any) {
  switch (comparator) {
    case ">": {
      switch (language) {
        case "en":
          return "Greater than";
        default:
          return "Maior que";
      }
    }
    case ">=": {
      switch (language) {
        case "en":
          return "Greater than or equal to";
        default:
          return "Maior ou igual a";
      }
    }
    case "<": {
      switch (language) {
        case "en":
          return "Lower than";
        default:
          return "Menor que";
      }
    }
    case ">=": {
      switch (language) {
        case "en":
          return "Lower than or equal to";
        default:
          return "Menor ou igual a";
      }
    }
  }
}

export function GetIcon(icon: string) {
  let Icon: any = Icons[icon as keyof typeof Icons];

  return Icon || Icons.Warning;
}

export function formatCurrency(value: any) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
  });

  return formatter.format(value);
}

export function collateString(string: string) {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function exportCsv(data: any, name?: string) {
  try {
    data = data
      .map((line: any) => {
        return line
          .map(String)
          .map((value: string) => `"${value}"`)
          .join(";");
      })
      .join("\r\n");

    const blob = new Blob(["\uFEFF" + data], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(blob, name || `Export.csv`);
  } catch {
    return;
  }
}

export function htmlToText(html: any) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

export function isColorDark(hexColor: any) {
  hexColor = hexColor.replace(/^#/, "");

  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luminance < 128;
}

export function getFieldName(field: any) {
  switch (field) {
    case "nome_completo":
      return "Nome Completo";

    case "telemovel":
      return "Telemóvel";

    case "email":
      return "Email";

    case "morada":
      return "Morada";

    case "codigo_postal":
      return "Código Postal";

    case "distrito":
      return "Distrito";

    case "concelho":
      return "Concelho";

    case "freguesia":
      return "Freguesia";

    case "genero":
      return "Género";

    case "numero_cc":
      return "Cartão Cidadão";

    case "cc_validade":
      return "Validade Cartão Cidadão";

    case "nif":
      return "NIF";

    case "niss":
      return "NISS";

    case "iban":
      return "IBAN";

    case "instituicao_bancaria":
      return "Instituição Bancária";

    case "num_colaborador":
      return "Nº Colaborador";

    case "id_nivel_hierarquico":
      return "Nível Hierárquico";

    case "id_setor":
      return "Setor";

    case "id_estabelecimento":
      return "Estabelecimento";

    case "id_funcao":
      return "Função";

    case "id_nivel":
      return "Nível";

    case "data_admissao":
      return "Data de Admissão";

    case "ativo":
      return "Ativo";

    case "id_grau_escolaridade":
      return "Grau de Habilitação";

    default:
      return field;
  }
}

export function timeToMinutes(hoursInitial: string) {
  if (hoursInitial.includes(":")) {
    const [hours, minutes] = hoursInitial.split(":").map(Number);
    return hours * 60 + minutes;
  } else {
    return 0;
  }
}
