import { SpinnerGap } from "@phosphor-icons/react";
import classes from "./Loading.module.css";

type Props = {
  onlyIcon?: boolean;
  size?: any;
  color?: any;
};

const Loading: React.FC<Props> = ({ onlyIcon, size, color }) => {
  return (
    <div className="fs-6 fw-semibold text-center ">
      <SpinnerGap
        className={classes.icon}
        size={size ?? 30}
        color={color ?? null}
      />{" "}
      {!onlyIcon ? <span className="ml-1">A carregar...</span> : ""}
    </div>
  );
};

export default Loading;
