import React, { useContext } from "react";
import Private from "./Private";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MasterLayout } from "../components/_metronic/layout/MasterLayout";
import { useSelector } from "react-redux";

//PAGES
import Login from "../pages/Auth/Login";
import Error404 from "../components/Errors/404";

const Recover = React.lazy(() => import("../pages/Auth/Recover"));
const FeedbackWithoutLogin = React.lazy(
  () => import("../pages/Evaluations/FeedbackConfirmation/FeedbackWithoutLogin")
);
const TrainingForms = React.lazy(
  () => import("../pages/Trainings/List/TrainingForms/TrainingsForms")
);
const FillQuiz = React.lazy(
  () => import("../pages/Recrutamento/Vagas/PreencherQuestionario")
);

const PublicRoutes: React.FC = () => {
  const user = useSelector((state: any) => state.user);
  const company = useSelector((state: any) => state.company);

  return (
    <BrowserRouter>
      <Routes>
        {company.dominio !== null ? (
          <>
            {company.dominio ? (
              <>
                <Route
                  path="/feedback/:hash"
                  element={<FeedbackWithoutLogin />}
                />
                <Route
                  path="/training-form/:type/:hash"
                  element={<TrainingForms />}
                />
                <Route path="/recruitment-form/:hash" element={<FillQuiz />} />
                {user.id ? (
                  <Route element={<MasterLayout />}>
                    <Route path="*" element={<Private />} />
                  </Route>
                ) : (
                  <>
                    <Route path="/login" element={<Login />} />
                    <Route path="/recover" element={<Recover />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                  </>
                )}
              </>
            ) : (
              <>
                <Route
                  path="404"
                  element={
                    <div className="not-found-page">
                      <Error404>
                        <p className="fs-2">Empresa não encontrada</p>
                      </Error404>
                    </div>
                  }
                />
                <Route path="*" element={<Navigate to="/404" />} />
              </>
            )}
          </>
        ) : (
          <Route path="*" element={<p>Loading...</p>} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default PublicRoutes;
