import React, { Suspense, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barThickness: 1,
  shadowBlur: 5,
});

const PrivateRoutes: React.FC = () => {
  const menus = useSelector((state: any) => state.menus.menus);

  const routes = useMemo(() => {
    if (menus) {
      let spreadMenus = [...menus];

      for (let i = 0; i < spreadMenus.length; i++) {
        if (spreadMenus[i].children) {
          spreadMenus.push(...spreadMenus[i].children);
        }
      }

      const allRoutes = [
        {
          path: "/home",
          element: React.lazy(() => import("../pages/Home/Home")),
          private: false,
        },
        {
          path: "/profile",
          element: React.lazy(() => import("../pages/Profile/Profile")),
          private: false,
        },
        //COMPANY
        {
          path: "/company/levels",
          element: React.lazy(
            () =>
              import("../pages/Company/HierarchicalLevels/HierarchicalLevels")
          ),
          private: true,
        },
        {
          path: "/company/sectors",
          element: React.lazy(() => import("../pages/Company/Sectors/AllTabs")),
          private: true,
        },
        {
          path: "/company/logs",
          element: React.lazy(() => import("../pages/Company/Logs/Logs")),
          private: true,
        },
        {
          path: "/company/permissions",
          element: React.lazy(() => import("../pages/Company/Access/Access")),
          private: true,
        },
        {
          path: "/company/group-menus",
          element: React.lazy(
            () => import("../pages/Company/MenuGroups/MenuGroups")
          ),
          private: true,
        },
        {
          path: "/company/routes",
          element: React.lazy(() => import("../pages/Company/Routes/Routes")),
          private: true,
        },
        {
          path: "/company/notifications",
          element: React.lazy(
            () => import("../pages/Company/Notifications/Notifications")
          ),
          private: true,
        },
        {
          path: "/company/roles",
          element: React.lazy(
            () => import("../pages/Company/RolesLevels/RolesLevels")
          ),
          private: true,
        },
        {
          path: "/company/facilities",
          element: React.lazy(
            () => import("../pages/Company/Facilities/Facilities")
          ),
          private: true,
        },
        {
          path: "/company/employees",
          element: React.lazy(
            () => import("../pages/Company/Employees/Employees")
          ),
          private: true,
        },
        {
          path: "/company/employees/:id",
          element: React.lazy(
            () => import("../pages/Company/Employees/Employee")
          ),
          private: "/company/employees",
        },
        //EVALUATIONS
        {
          path: "/evaluations/configurations",
          element: React.lazy(
            () => import("../pages/Evaluations/Configuration/Configuration")
          ),
          private: true,
        },
        {
          path: "/evaluations/maintenance",
          element: React.lazy(
            () => import("../pages/Evaluations/Maintenance/Maintenance")
          ),
          private: true,
        },
        {
          path: "/evaluations/feedback-confirmation",
          element: React.lazy(
            () =>
              import(
                "../pages/Evaluations/FeedbackConfirmation/FeedbackConfirmation"
              )
          ),
          private: true,
        },
        {
          path: "/evaluations/list",
          element: React.lazy(
            () => import("../pages/Evaluations/Evaluations/Evaluations")
          ),
          private: true,
        },
        {
          path: "/evaluations/list/:id",
          element: React.lazy(
            () => import("../pages/Evaluations/Evaluations/EvaluationForm")
          ),
          private: "/evaluations/list",
        },
        {
          path: "/evaluations/extraordinary",
          element: React.lazy(
            () => import("../pages/Evaluations/Extraordinary/List")
          ),
          private: true,
        },
        {
          path: "/evaluations/extraordinary/new",
          element: React.lazy(
            () => import("../pages/Evaluations/Extraordinary/Create")
          ),
          private: "/evaluations/extraordinary",
        },
        {
          path: "/evaluations/sector-evaluations",
          element: React.lazy(
            () =>
              import("../pages/Evaluations/SectorEvaluations/SectorEvaluations")
          ),
          private: true,
        },
        {
          path: "/evaluations/sector-evaluations/:id",
          element: React.lazy(
            () =>
              import(
                "../pages/Evaluations/SectorEvaluations/SectorEvaluationForm"
              )
          ),
          private: "/evaluations/sector-evaluations",
        },
        {
          path: "/evaluations/self-evaluations",
          element: React.lazy(
            () => import("../pages/Evaluations/SelfEvaluation/SelfEvaluations")
          ),
          private: true,
        },
        {
          path: "/evaluations/self-evaluations/:id",
          element: React.lazy(
            () => import("../pages/Evaluations/Evaluations/EvaluationForm")
          ),
          private: "/evaluations/self-evaluations",
        },
        {
          path: "/evaluations/schedules",
          element: React.lazy(
            () => import("../pages/Evaluations/Schedule/Schedules")
          ),
          private: true,
        },
        {
          path: "/evaluations/schedules/:id",
          element: React.lazy(
            () => import("../pages/Evaluations/Schedule/Schedule")
          ),
          private: "/evaluations/schedules",
        },
        //EVALUATIONS - REPORTS
        {
          path: "/evaluations/reports",
          element: React.lazy(
            () => import("../pages/Evaluations/Reports/Reports")
          ),
          private: true,
        },
        //EVALUATIONS - REPORTS - EMPLOYEES
        {
          path: "/evaluations/reports/evolution",
          element: React.lazy(
            () =>
              import("../pages/Evaluations/Reports/Employees/AvaliacaoEvolucao")
          ),
          private: true,
        },
        {
          path: "/evaluations/reports/state",
          element: React.lazy(
            () =>
              import("../pages/Evaluations/Reports/Employees/EstadoAvaliacoes")
          ),
          private: true,
        },
        {
          path: "/evaluations/reports/feedback",
          element: React.lazy(
            () =>
              import(
                "../pages/Evaluations/Reports/Employees/ConfirmacaoFeedback"
              )
          ),
          private: true,
        },
        {
          path: "/evaluations/reports/aggregated",
          element: React.lazy(
            () =>
              import("../pages/Evaluations/Reports/Employees/ConsultaAgregada")
          ),
          private: true,
        },
        {
          path: "/evaluations/reports/training",
          element: React.lazy(
            () =>
              import("../pages/Evaluations/Reports/Employees/PropostaFormacao")
          ),
          private: true,
        },
        {
          path: "/evaluations/reports/skills",
          element: React.lazy(
            () =>
              import(
                "../pages/Evaluations/Reports/Employees/AvaliacaoCompetencias"
              )
          ),
          private: true,
        },
        {
          path: "/evaluations/reports/simulator",
          element: React.lazy(
            () => import("../pages/Evaluations/Reports/Employees/Simulador")
          ),
          private: false,
        },
        //EVALUATIONS - REPORTS - SECTORS
        {
          path: "/evaluations/reports/sector-evolution",
          element: React.lazy(
            () =>
              import("../pages/Evaluations/Reports/Sectors/AvaliacaoEvolucao")
          ),
          private: true,
        },
        {
          path: "/evaluations/reports/sector-state",
          element: React.lazy(
            () =>
              import("../pages/Evaluations/Reports/Sectors/EstadoAvaliacoes")
          ),
          private: true,
        },
        //EVALUATIONS - REPORTS - GLOBAL
        {
          path: "/evaluations/reports/global",
          element: React.lazy(
            () => import("../pages/Evaluations/Reports/Global/Global")
          ),
          private: true,
        },
        {
          path: "/evaluations/reports/export-global",
          element: React.lazy(
            () => import("../pages/Evaluations/Reports/Global/ExportarGlobal")
          ),
          private: true,
        },
        {
          path: "/evaluations/reports/questions",
          element: React.lazy(
            () =>
              import("../pages/Evaluations/Reports/Global/ExportarPerguntas")
          ),
          private: true,
        },
        //EVALUATIONS - HISTORIC
        {
          path: "/evaluations/historic",
          element: React.lazy(
            () => import("../pages/Evaluations/History/History")
          ),
          private: true,
        },
        {
          path: "/evaluations/historic/consult",
          element: React.lazy(
            () =>
              import(
                "../pages/Evaluations/History/Cards/Collaborators/EmployeeEvaluation"
              )
          ),
          private: true,
        },
        {
          path: "/evaluations/historic/sectors-evaluation",
          element: React.lazy(
            () =>
              import(
                "../pages/Evaluations/History/Cards/MySector/MySectorEvaluation"
              )
          ),
          private: true,
        },
        //SIMULATOR
        {
          path: "/evaluations/simulator",
          element: React.lazy(
            () => import("../pages/Evaluations/Simulator/Simulations/List")
          ),
          private: true,
        },
        {
          path: "/evaluations/simulator/maintenance",
          element: React.lazy(
            () =>
              import("../pages/Evaluations/Simulator/Maintenance/Maintenance")
          ),
          private: "/evaluations/simulator",
        },
        {
          path: "/evaluations/simulator/new",
          element: React.lazy(
            () => import("../pages/Evaluations/Simulator/Main")
          ),
          private: "/evaluations/simulator",
        },
        {
          path: "/evaluations/simulator/:id",
          element: React.lazy(
            () => import("../pages/Evaluations/Simulator/Simulations/Detail")
          ),
          private: true,
        },
        //TRAININGS
        {
          path: "/trainings/maintenance",
          element: React.lazy(
            () => import("../pages/Trainings/Maintenance/Maintenance")
          ),
          private: true,
        },
        {
          path: "/trainings/list",
          element: React.lazy(() => import("../pages/Trainings/List/List")),
          private: true,
        },
        {
          path: "/trainings/list/:id",
          element: React.lazy(() => import("../pages/Trainings/List/Training")),
          private: "/trainings/list",
        },
        {
          path: "/trainings/plan",
          element: React.lazy(
            () => import("../pages/Trainings/Plans/PlansList")
          ),
          private: true,
        },
        {
          path: "/trainings/configurations",
          element: React.lazy(
            () => import("../pages/Trainings/Configuration/Configuration")
          ),
          private: true,
        },
        {
          path: "/trainings/plan/:id",
          element: React.lazy(
            () => import("../pages/Trainings/Plans/TrainingPlan")
          ),
          private: "/trainings/plan",
        },
        {
          path: "/trainings/summary",
          element: React.lazy(
            () => import("../pages/Trainings/Summary/Summary")
          ),
          private: true,
        },
        {
          path: "/trainings/reports",
          element: React.lazy(
            () => import("../pages/Trainings/Reports/Relatorios")
          ),
          private: true,
        },
        {
          path: "/trainings/reports/hours",
          element: React.lazy(
            () => import("../pages/Trainings/Reports/Content/HorasFormacao")
          ),
          private: true,
        },
        {
          path: "/trainings/reports/general",
          element: React.lazy(
            () => import("../pages/Trainings/Reports/Content/RelatorioGeral")
          ),
          private: true,
        },
        {
          path: "/training/reports/cancellations",
          element: React.lazy(
            () =>
              import("../pages/Trainings/Reports/Content/MotivosCancelamento")
          ),
          private: true,
        },
        {
          path: "/training/reports/plan",
          element: React.lazy(
            () => import("../pages/Trainings/Reports/Content/RelatorioPlano")
          ),
          private: true,
        },
        {
          path: "/training/reports/no-training",
          element: React.lazy(
            () => import("../pages/Trainings/Reports/Content/FormacaoEmFalta")
          ),
          private: true,
        },
        //INTERVIEWS
        {
          path: "/interviews/maintenance",
          element: React.lazy(
            () => import("../pages/ExitInterviews/Maintenance/Maintenance")
          ),
          private: true,
        },
        {
          path: "/interviews/list",
          element: React.lazy(
            () => import("../pages/ExitInterviews/List/List")
          ),
          private: true,
        },
        {
          path: "/interviews/list/:id",
          element: React.lazy(
            () => import("../pages/ExitInterviews/List/ExitInterview")
          ),
          private: "/interviews/list",
        },
        //INTERVIEWS - REPORTS
        {
          path: "/interviews/reports",
          element: React.lazy(
            () => import("../pages/ExitInterviews/Reports/Reports")
          ),
          private: false,
        },
        {
          path: "/interviews/reports/reasons",
          element: React.lazy(
            () => import("../pages/ExitInterviews/Reports/Content/GeralReport")
          ),
          private: true,
        },
        //RECRUITMENT
        {
          path: "/recruitment/maintenance",
          element: React.lazy(
            () => import("../pages/Recrutamento/Manutencao/Manutencao")
          ),
          private: true,
        },
        {
          path: "/recruitment/openings",
          element: React.lazy(
            () => import("../pages/Recrutamento/Vagas/Vagas")
          ),
          private: true,
        },
        {
          path: "/recruitment/openings/:id",
          element: React.lazy(() => import("../pages/Recrutamento/Vagas/Vaga")),
          private: "/recruitment/openings",
        },
        {
          path: "/recruitment/candidates",
          element: React.lazy(
            () => import("../pages/Recrutamento/Candidatos/Candidatos")
          ),
          private: true,
        },
        {
          path: "/recruitment/candidates/:id",
          element: React.lazy(
            () => import("../pages/Recrutamento/Candidatos/Candidato")
          ),
          private: "/recruitment/candidates",
        },
        //PORTAL
        {
          path: "/portal/home",
          element: React.lazy(
            () => import("../pages/EmployeePortal/Home/Home")
          ),
          private: true,
        },
        {
          path: "/portal/home/evaluation",
          element: React.lazy(
            () => import("../pages/EmployeePortal/Home/Evaluation/Evaluation")
          ),
          private: true,
        },
        {
          path: "/portal/home/trainings",
          element: React.lazy(
            () => import("../pages/EmployeePortal/Home/Trainings/Trainings")
          ),
          private: true,
        },
        {
          path: "/portal/home/my-data",
          element: React.lazy(
            () => import("../pages/EmployeePortal/MyData/MyData")
          ),
          private: true,
        },
        {
          path: "/portal/requests/list",
          element: React.lazy(
            () =>
              import("../pages/EmployeePortal/ChangeRequests/ChangeRequests")
          ),
          private: true,
        },
        {
          path: "/portal/maintenance",
          element: React.lazy(
            () => import("../pages/EmployeePortal/Maintenance/Maintenance")
          ),
          private: true,
        },
        {
          path: "/portal/home/my-team",
          element: React.lazy(
            () => import("../pages/EmployeePortal/Home/Team/Team")
          ),
          private: true,
        },
        {
          path: "/portal/home/communications",
          element: React.lazy(
            () =>
              import(
                "../pages/EmployeePortal/Home/Communications/CommunicationsList"
              )
          ),
          private: true,
        },
        {
          path: "/portal/home/communications/:id",
          element: React.lazy(
            () =>
              import(
                "../pages/EmployeePortal/Home/Communications/Communication"
              )
          ),
          private: true,
        },
        {
          path: "/portal/home/holiday/:id",
          element: React.lazy(
            () =>
              import(
                "../pages/EmployeePortal/Home/HolidayRequest/HolidayRequest"
              )
          ),
          private: false,
        },
        //ONBOARDING
        {
          path: "/onboarding/home",
          element: React.lazy(() => import("../pages/Onboarding/Home")),
          private: true,
        },
        {
          path: "/onboarding/tasks",
          element: React.lazy(() => import("../pages/Onboarding/Tasks")),
          private: true,
        },
        {
          path: "/onboarding/list",
          element: React.lazy(() => import("../pages/Onboarding/List")),
          private: true,
        },
        {
          path: "/onboarding/new/:id",
          element: React.lazy(() => import("../pages/Onboarding/New")),
          private: true,
        },
        {
          path: "/onboarding/list/:id",
          element: React.lazy(() => import("../pages/Onboarding/Details")),
          private: true,
        },
        {
          path: "/onboarding/my-data",
          element: React.lazy(() => import("../pages/Onboarding/MyData")),
          private: true,
        },
        {
          path: "/onboarding/maintenance",
          element: React.lazy(
            () => import("../pages/Onboarding/Maintenance/Maintenance")
          ),
          private: true,
        },
        //ADMISSION
        {
          path: "/admission-application/maintenance",
          element: React.lazy(
            () => import("../pages/Admissions/Maintenance/Maintenance")
          ),
          private: true,
        },
        {
          path: "/admission-application/list",
          element: React.lazy(
            () =>
              import(
                "../pages/Admissions/AdmissionsRequests/AdmissionsRequests"
              )
          ),
          private: true,
        },

        {
          path: "/admission-application/list/:id",
          element: React.lazy(
            () => import("../pages/Admissions/AdmissionsRequests/Admission")
          ),
          private: "/admission-application/list",
        },

        //Eventos

        {
          path: "/events/maintenance",
          element: React.lazy(
            () => import("../pages/Events/Maintenance/Maintenance")
          ),
          private: true,
        },

        {
          path: "/events/list",
          element: React.lazy(() => import("../pages/Events/List/List")),
          private: true,
        },

        {
          path: "/events/list/:id",
          element: React.lazy(() => import("../pages/Events/List/Event")),
          private: "/events/list",
        },

        {
          path: "/events/form/:id",
          element: React.lazy(() => import("../pages/Events/List/Form")),
          private: false,
        },

        //FÉRIAS
        {
          path: "/holidays/maintenance",
          element: React.lazy(() => import("../pages/Holidays/Maintenance")),
          private: true,
        },
        {
          path: "/holidays/configs",
          element: React.lazy(
            () => import("../pages/Holidays/Configuration/Configuration")
          ),
          private: true,
        },
        {
          path: "/holidays/list",
          element: React.lazy(() => import("../pages/Holidays/List")),
          private: true,
        },
        {
          path: "/holidays/list/:id",
          element: React.lazy(() => import("../pages/Holidays/Request")),
          private: true,
        },

        //TURNOS
        {
          path: "/company/shifts",
          element: React.lazy(() => import("../pages/Company/Shifts/Shifts")),
          private: true,
        },

        //CADASTRO
        {
          path: "/company/registration",
          element: React.lazy(
            () => import("../pages/Company/Cadastro/Cadastro")
          ),
          private: true,
        },
        {
          path: "/company/registration/:id",
          element: React.lazy(() => import("../pages/Company/Cadastro/User")),
          private: "/company/registration",
        },

        //TIPOS DOCUMENTOS
        {
          path: "/company/document-types",
          element: React.lazy(
            () => import("../pages/Company/DocumentTypes/DocumentTypes")
          ),
          private: true,
        },

        //Graus de Qualificação
        {
          path: "/company/education-levels",
          element: React.lazy(
            () => import("../pages/Company/Qualifications/Qualifications")
          ),
          private: true,
        },
      ];

      return allRoutes.map((route: any) => {
        if (
          route.private &&
          !spreadMenus.some((menu: any) =>
            route.private && route.private.length
              ? menu.path === route.private
              : menu.path === route.path.split("/:id")[0]
          )
        ) {
          route.element = () => Navigate({ to: "/404" });
        }
        return route;
      });
    }
    return [];
  }, [menus]);

  return (
    <Routes>
      {routes.map((Obj: any, index: number) => {
        return (
          <Route
            key={index}
            path={Obj.path}
            element={
              <Suspense fallback={<TopBarProgress />}>
                <Obj.element />
              </Suspense>
            }
          />
        );
      })}
      <Route path="/login" element={<Navigate to="/home" />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};

export default PrivateRoutes;
