import classes from "./FullPageLoading.module.css";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { SpinnerGap } from "@phosphor-icons/react";
import { useSelector } from "react-redux";

const FullPageLoading: React.FC = () => {
  const company = useSelector((state: any) => state.company);

  return (
    <div className={classes.mainDiv}>
      <img
        src={toAbsoluteUrl(
          `/media/clients/${company.dominio}/logo/logo_full.png`
        )}
        alt=""
        width="460px"
        className="mb-15"
      />
      <SpinnerGap className={classes.icon} size={50} strokeWidth="20px" />
    </div>
  );
};
export default FullPageLoading;
