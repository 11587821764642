import React from "react";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { GetIcon } from "../../../../../config/icons";

type Props = {
  menu: any;
};

const AsideMenuItem: React.FC<Props> = ({ menu }) => {
  const location = useLocation().pathname;

  let JSXIcon = menu.icone ? GetIcon(menu.icone) : null;

  function isActive() {
    if (location) {
      return location.startsWith(menu.path);
    }
  }

  return (
    <div className={clsx("menu-item", { "here show": isActive() })}>
      <Link
        className={clsx("menu-link without-sub")}
        to={menu.path}
        key={"link" + menu.id}
      >
        {JSXIcon ? (
          <span className="mr-2" style={{ position: "relative", top: "-2px" }}>
            <JSXIcon
              // fill="rgb(var(--tertiary))"
              size={18}
              color="white"
              weight="thin"
            />
          </span>
        ) : (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        <span className="menu-title">{menu.menu}</span>
      </Link>
    </div>
  );
};

export { AsideMenuItem };
