/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  notifications: any;
  toRead: any;
}

const HeaderNotificationsMenu: FC<Props> = ({ notifications, toRead }) => {
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px p-0"
      data-kt-menu="true"
    >
      {toRead ? (
        <p className="fw-bolder py-5 px-5 mb-none">
          {toRead} Notificações por ler
        </p>
      ) : (
        <p className="text-muted py-5 px-5 mb-none">Sem notificações por ler</p>
      )}
      <div className="scroll-y mh-325px">
        {notifications?.length
          ? notifications.map((item: any, index: any) =>
              item.titulo ? (
                <Link
                  key={`item_${item.id}_${index}`}
                  to={item.path}
                  role="button"
                >
                  <div
                    className={
                      "d-flex flex-column border-top border-secondary px-5 py-3"
                    }
                    style={{
                      backgroundColor: item.lida == "S" ? "" : "#F8F8F8",
                    }}
                  >
                    <p className="fw-bolder ">{item.titulo}</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.subtitulo,
                      }}
                    ></p>
                    <p className="mb-none mt-2 fs-11">
                      {moment(item.data_envio).format("LLL")}
                    </p>
                  </div>
                </Link>
              ) : null
            )
          : null}
      </div>
    </div>
  );
};

export { HeaderNotificationsMenu };
