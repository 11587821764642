import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    access_token: null,
    id: null,
    id_user_empresa: null,
    id_empresa: null,
    first_name: "",
    last_name: "",
    full_name: "",
    initials: "",
    email: "",
    phone: "",
    gender: "",
    language: "",
    img_ext: "",
    cor_setor: "",
  },
  reducers: {
    login(state: any, action: PayloadAction<any>) {
      state.access_token = action.payload.access_token;
    },

    saveInfo(state: any, action: PayloadAction<any>) {
      state.id = action.payload.id;
      state.id_user_empresa = action.payload.id_user_empresa;
      state.id_empresa = action.payload.id_empresa;
      state.first_name = action.payload.primeiro_nome;
      state.last_name = action.payload.ultimo_nome;
      state.email = action.payload.email;
      state.phone = action.payload.telemovel;
      state.initials = action.payload.iniciais;
      state.gender = action.payload.genero;
      state.full_name = action.payload.nome_completo;
      state.language = action.payload.idioma;
      state.img_ext = action.payload.extensao_img_user;
      state.cor_setor = action.payload.cor_setor;
    },

    logout(state: any) {
      state.access_token = null;
      state.id = null;
      state.id_user_empresa = null;
      state.id_empresa = null;
      state.first_name = "";
      state.last_name = "";
      state.full_name = "";
      state.email = "";
      state.phone = "";
      state.initials = "";
      state.gender = "";
      state.language = "";
      state.img_ext = "";
      state.cor_setor = "";
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
