import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
  name: "company",
  initialState: {
    id: null,
    nome: null,
    dominio: null,
    cor_1: null,
    cor_2: null,
    cor_3: null,
    cor_4: null,
    titulo_login_1: null,
    titulo_login_2: null,
  },
  reducers: {
    save(state: any, action: PayloadAction<any>) {
      state.id = action.payload.id;
      state.nome = action.payload.nome;
      state.dominio = action.payload.dominio;
      state.cor_1 = action.payload.cor_1;
      state.cor_2 = action.payload.cor_2;
      state.cor_3 = action.payload.cor_3;
      state.cor_4 = action.payload.cor_4;
      state.titulo_login_1 = action.payload.titulo_login_1;
      state.titulo_login_2 = action.payload.titulo_login_2;
    },
  },
});

export const companyActions = companySlice.actions;

export default companySlice;
