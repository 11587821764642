import classes from "./404.module.css";

const Error404 = ({ children }: any) => {
  return (
    <div className={classes.error404}>
      <h1>ERROR 404</h1>
      {children}
    </div>
  );
};

export default Error404;
