import axios from "../config";

export const getInfo = (access_token: string) => {
  return axios.get("/users", {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getAllUsers = (
  access_token: string,
  limit: any,
  offset: any,
  order: any,
  search: string,
  active: number,
  sector?: any,
  company?: any,
  key?: string,
  access?: 1 | 0,
  allIds?: boolean | number
) => {
  return axios.get(`/users/all`, {
    params: {
      limit,
      offset,
      order,
      search,
      active,
      sector,
      company: company || undefined,
      key,
      access,
      allIds,
    },
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getAllUsersWithCompanies = (
  access_token: string,
  limit: any,
  offset: any,
  order: any,
  search: string,
  active: number,
  sector?: any,
  company?: any
) => {
  return axios.get(
    `/users/all-users?offset=${offset}&limit=${limit}&order=${order[0]}&order=${
      order[1]
    }&active=${active}&search=${search}&sector=${sector ? sector : ""}${
      company ? `&empresa=${company}` : ""
    }`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const getAllPendingUsers = (
  access_token: string,
  limit: any,
  offset: any,
  order: any,
  search: string
) => {
  return axios.get(
    `/users/pending-users?offset=${offset}&limit=${limit}&order=${order[0]}&order=${order[1]}&search=${search}`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const updatePendingUser = (
  access_token: string,
  id_user: any,
  body: any
) => {
  return axios.put(`/users/pending-user/${id_user}`, body, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getUserById = (access_token: string, id: number) => {
  return axios.get(`/users/${id}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getUserByIdUser = (access_token: string, id: number) => {
  return axios.get(`/users/info/${id}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const updateUser = (
  access_token: string,
  user: any,
  userId?: number
) => {
  return axios.put(`/users${userId ? `?userId=${userId}` : ""}`, user, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const createUser = (access_token: string, user: any) => {
  return axios.post("/users", user, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const patchLanguage = (access_token: string, language: any) => {
  return axios.patch(
    "/users/language",
    { idioma: language },
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const getAllUsersTypes = (access_token: string, active?: any) => {
  return axios.get(`/users/types${active ? `?ativo=${active}` : ""}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const verifyUsers = (
  access_token: string,
  classId: any,
  body: any,
  company?: any
) => {
  return axios.post(
    `/trainings/users/${classId}${company ? `?empresa=${company}` : ""}`,
    body,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const getUserCompanies = (access_token: string, idUser: any) => {
  return axios.get(`/users/companies/${idUser}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getAllUserStates = (access_token: string, active?: any) => {
  return axios.get(`/users/states`, {
    params: {
      active: active || "",
    },
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const createUserProfilePhoto = (
  access_token: string,
  id_user: any,
  formData: any
) => {
  return axios.post(`/users/profile/${id_user}`, formData, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getUserProfilePhoto = (access_token: string, id_user: any) => {
  return axios.get(`/users/profile/${id_user}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const access = (access_token: string, body: any) => {
  return axios.post(`/users/access`, body, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const createUserCadastro = (access_token: string, user: any) => {
  return axios.post(`/users/cadastro`, user, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const updateUserCadastro = (
  access_token: string,
  user: any,
  userId?: number
) => {
  return axios.put(`/users/cadastro/${userId}`, user, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const createUserAnnex = (access_token: string, body: any) => {
  return axios.post(`/users/annex`, body, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const updateUserAnnex = (access_token: string, id: any, body: any) => {
  return axios.put(`/users/annex/${id}`, body, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const deleteUserAnnex = (access_token: string, id: any) => {
  return axios.delete(`/users/annex/${id}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export function getUserFiles(
  accessToken: string,
  id: number,
  annexIds?: number[]
) {
  return axios.get(`/users/annexs/${id}`, {
    params: {
      files: annexIds,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}
