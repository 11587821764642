import clsx from "clsx";
import { FC, useEffect, useMemo, useState } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { HeaderNotificationsMenu, HeaderUserMenu } from "../../../partials";
import { useSelector } from "react-redux";
import { Bell } from "@phosphor-icons/react";
import {
  getUserNotifications,
  readNotifications,
} from "../../../../../axios/requests/notifications";
import { getAllLanguages } from "../../../../../axios/requests/languages";
import { setLanguage } from "../../../i18n/Metronici18n";
import { patchLanguage } from "../../../../../axios/requests/users";
import UserPhoto from "../../../../UI/UserPhoto/UserPhoto";
import { getInitials } from "../../../../../utilities/utilities";
import { getUserCompanys } from "../../../../../axios/requests/company";
import SelectCompany from "../../../../UI/SelectCompany/SelectCompany";
import { useQuery } from "@tanstack/react-query";

const toolbarButtonMarginClass = "pointer ml-5";

const Topbar: FC = () => {
  const user = useSelector((state: any) => state.user);
  const company = useSelector((state: any) => state.company);

  const [companys, setCompanys] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);

  const notifications = useQuery<any>({
    queryKey: ["notifications", user.id_user_empresa],
    queryFn: getNotifications,
    refetchInterval: 120000,
    refetchOnWindowFocus: true,
  });

  const [languages, setLanguages] = useState<any>([]);

  const notificationsToRead = useMemo(() => {
    return notifications.data?.filter((item: any) => !item.lida).length;
  }, [notifications.data]);

  useEffect(() => {
    getUserCompanys(user.access_token).then((res: any) => {
      setCompanys(
        res.data.map((obj: any) => {
          return {
            value: obj.dominio,
            label: obj.descricao,
          };
        })
      );
      if (res.data.length > 1) {
        setSelectedCompany({
          value: res.data.find((obj: any) => obj.id == user.id_empresa)
            ?.dominio,
          label: res.data.find((obj: any) => obj.id == user.id_empresa)
            ?.descricao,
        });
      }
    });
  }, []);

  async function getNotifications() {
    const notifications = await getUserNotifications(user.access_token).then(
      (res) => {
        return res.data;
      }
    );

    return notifications;
  }

  function getLanguages() {
    getAllLanguages(user.access_token, 1).then((res) => {
      setLanguages(res.data);
    });
  }

  function updateNotifications() {
    if (notificationsToRead) {
      readNotifications(user.access_token).then(() => {
        notifications.refetch();
      });
    }
  }

  function updateUserLanguage(language: any) {
    patchLanguage(user.access_token, language.idioma).then((res) => {
      setLanguage(language.idioma);
    });
  }

  useEffect(() => {
    getNotifications();
    getLanguages();
  }, []);

  return (
    <div className="d-flex align-items-center">
      {companys.length > 1 ? (
        // <Select
        //   options={companys}
        //   state={company.dominio}
        //   stateUpdate={(value: any) => {
        //     if (value != company.dominio)
        //       window.open(`https://${value}.frejen.pt`, "_blank");
        //   }}
        //   placeholder="Empresa"
        // />
        <SelectCompany
          selectedValue={selectedCompany}
          options={companys}
          onSelect={(value: any) => {
            if (value.value != company.dominio)
              window.open(`https://${value.value}.hrstudium.pt`, "_blank");
          }}
        />
      ) : null}

      {/* NOTIFICATIONS */}

      <div className={toolbarButtonMarginClass}>
        <div
          className={clsx(
            "d-flex justify-content-end align-items-start p-2 rounded bg-secondary"
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
          onClick={() => {
            updateNotifications();
          }}
        >
          <Bell size={20} weight="regular" />
          {notificationsToRead ? (
            <span className="label label-rounded label-notification">
              {notificationsToRead < 100 ? notificationsToRead : "99+"}
            </span>
          ) : null}
        </div>

        <HeaderNotificationsMenu
          notifications={notifications.data}
          toRead={notificationsToRead}
        />
      </div>

      {/* LANGUAGES */}

      <div
        className={clsx(toolbarButtonMarginClass, "w-30px pointer")}
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <img
          src={toAbsoluteUrl(`/media/flags/${user.language}.svg`)}
          className="rounded"
        />
        <div
          className="menu menu-sub menu-sub-dropdown menu-column w-250px p-5 scroll-y mh-220px"
          data-kt-menu="true"
        >
          {languages.map((language: any, index: any) => {
            return (
              <div
                key={"language_" + index}
                className={clsx(
                  "rounded hover d-flex align-items-center",
                  index < languages.length - 1 && "mb-5"
                )}
                onClick={() => {
                  updateUserLanguage(language);
                }}
              >
                <img
                  src={toAbsoluteUrl(`/media/flags/${language.idioma}.svg`)}
                  className="h-30px mr-3"
                />
                <p className="fs-6 fw-semibold">{language.descricao}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* USER */}
      <div
        className={clsx(toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        <div
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <UserPhoto
            user={user}
            initials={getInitials(user.full_name)}
            size={40}
            showImage={true}
          />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Topbar };
