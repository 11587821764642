import React from "react";
import clsx from "clsx";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import Loading from "../Loading/Loading";
import classes from "./SelectCompany.module.css";
interface SelectProps {
  selectedValue: any;
  options: any;
  onSelect: (value: string) => void;
}

const SelectCompany: React.FC<SelectProps> = ({
  selectedValue,
  options,
  onSelect,
}) => {
  const selectOption = (option: string) => {
    onSelect(option);
  };

  const popover = (
    <Popover>
      <Popover.Body className={clsx(classes.scroll, "pb-0 p-5 ")}>
        {options && options.length
          ? options.map((option: any, index: any) => (
              <div
                key={index}
                className={clsx(
                  "option pointer mb-5 mt-2 d-flex align-items-center",
                  index < options.length - 1 && "mb-2",
                  classes.principalDiv
                )}
                onClick={() => {
                  selectOption(option);
                  document.body.click();
                }}
              >
                <img
                  src={toAbsoluteUrl(
                    `/media/clients/${option.value}/logo/logo.png`
                  )}
                  className="w-50px  mr-2"
                />
                <span className="fs-6 fw-semibold">{option.label}</span>
              </div>
            ))
          : null}
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="custom-select pointer">
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        rootClose={true}
      >
        {selectedValue?.value ? (
          <div className="selected-option">
            <img
              style={{ objectFit: "contain" }}
              src={toAbsoluteUrl(
                `/media/clients/${selectedValue.value}/logo/logo.png`
              )}
              className="rounded w-50px p-1"
            />
          </div>
        ) : (
          <Loading onlyIcon />
        )}
      </OverlayTrigger>
    </div>
  );
};

export default SelectCompany;
