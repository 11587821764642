import clsx from "clsx";
import classes from "./LoadingButton.module.css";
import { SpinnerGap } from "@phosphor-icons/react";

type Props = {
  loading: boolean;
  title?: any;
  submit?: boolean;
  onClick?: any;
  style?: string;
  size?: any;
  space?: string;
  disabled?: boolean;
  icon?: any;
  form?: any;
  hoverText?: any;
};

const LoadingButton: React.FC<Props> = ({
  loading,
  title,
  submit,
  onClick,
  style,
  space,
  size,
  disabled,
  icon,
  form,
  hoverText,
}) => {
  return (
    <button
      title={hoverText && hoverText}
      className={clsx(style ? style : "btn btn-primary", size, space)}
      type={submit ? "submit" : "button"}
      disabled={loading || disabled}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      form={form}
    >
      {loading ? (
        <SpinnerGap className={clsx(classes.icon, title && "mr-1")} size={16} />
      ) : (
        icon
      )}
      {title}
    </button>
  );
};

export default LoadingButton;
