import { useEffect, useState } from "react";
import clsx from "clsx";
import { getUserProfilePhoto } from "../../../axios/requests/users";
import { useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import { useQuery } from "@tanstack/react-query";

type Props = {
  user: any;
  size?: number;
  text_size?: number;
  space?: string;
  initials?: any;
  gender?: any;
  active?: boolean;
  onClick?: any;
  primeiroUltimoNome?: any;
  mostraNumero?: any;
  noName?: any;
  showImage?: boolean;
};

const UserPhoto: React.FC<Props> = ({
  user,
  size,
  text_size,
  space,
  initials,
  active,
  onClick,
  primeiroUltimoNome,
  mostraNumero,
  noName,
  showImage,
}) => {
  const token = useSelector((state: any) => state.user.access_token);

  const { data, isLoading }: any = useQuery({
    queryKey: ["userImage", user.id || user.nome_completo],
    queryFn: () => getPhoto(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
  async function getPhoto() {
    if (user.id && showImage) {
      try {
        const res = await getUserProfilePhoto(token, user.id);
        if (res.data.file?.data) {
          const bytes = new Uint8Array(res.data.file.data);

          const blob = new Blob([bytes], {
            type: res.data.mimetype,
          });

          return { source: URL.createObjectURL(blob) };
        }
      } catch {
        return {};
      }
    }
    return {};
  }

  return (
    <>
      {!isLoading ? (
        <>
          {data?.source ? (
            <img
              src={data.source}
              width={size ? `${size}px` : "50px"}
              height={size ? `${size}px` : "50px"}
              style={{
                objectFit: "fill",
                border:
                  user?.cor_setor && !active
                    ? `2px solid ${user?.cor_setor}`
                    : "",
                outline: active ? "3px dashed black" : "",
              }}
              className={clsx("rounded", space && space)}
              onClick={() => {
                if (onClick) {
                  onClick(user);
                }
              }}
            />
          ) : (
            <>
              <div
                onClick={() => {
                  if (onClick) {
                    onClick(user);
                  }
                }}
                style={{
                  display: "inline-block",
                  width: size ? `${size}px` : "50px",
                  height: "auto",
                  textAlign: "center",
                }}
                className={clsx(space && space)}
              >
                <div
                  className="d-flex align-items-center justify-content-center fw-semibold text-white"
                  style={{
                    outline: active ? "3px dashed black" : "",
                    fontSize: `${text_size || 14}px`,
                    width: size ? `${size}px` : "50px",
                    height: size ? `${size}px` : "50px",
                    borderRadius: "0.475rem",
                    backgroundColor: user.cor_setor ? user.cor_setor : "black",
                  }}
                >
                  <span>{initials}</span>
                </div>

                {(primeiroUltimoNome || user.nome_completo) && !noName ? (
                  <p className="my-1 fs-6">
                    {primeiroUltimoNome
                      ? primeiroUltimoNome
                      : user.nome_completo}
                  </p>
                ) : null}

                {mostraNumero ? <small>{user.num_colaborador}</small> : null}
              </div>
            </>
          )}
        </>
      ) : (
        <div
          style={{
            width: size ? `${size}px` : "50px",
            height: size ? `${size}px` : "50px",
          }}
        >
          <Loading onlyIcon />
        </div>
      )}
    </>
  );
};

export default UserPhoto;
